<template>
  <nav role="navigation" class="tw-flex tw-gap-x-4 tw-justify-center md:tw-justify-between tw-flex-wrap-reverse">
    <Search class="tw-w-full md:tw-w-auto" />
    <div class="tw-flex tw-flex-wrap tw-gap-2 tw-items-center tw-justify-center md:tw-justify-end md:tw-pr-4 tw-py-4">
      <ApplicationStatus class="tw-hidden md:tw-block" />
      <router-link :to="{ name: 'Planner' }" class="btn btn-primary">
        <i class="glyphicon glyphicon-plus-sign" />
        Lead
      </router-link>
      <button type="button" class="btn btn-success" @click="showMailClient">
        <i class="glyphicon glyphicon-envelope" />
      </button>
      <button type="button" class="btn btn-success" @click="showPermanenceModal">
        <i class="fas fa-phone" />
      </button>
      <NotificationDropdown />
      <div class="tw-ml-4 tw-hidden md:tw-block">
        <a
          target="_blank"
          href="https://dewaele.atlassian.net/servicedesk/customer/portal/4/group/-1"
          class="tw-text-gray-400 tw-mr-2"
        >
          <i class="fa fa-question" /> Help
        </a>
        <a href="/authentication/logout" class="tw-text-gray-400 tw-inline-block">
          <i class="fa fa-sign-out" /> Afmelden
        </a>
      </div>
    </div>
    <PermanenceModal ref="permanenceModal" />
    <MailClient ref="mailClient" />
  </nav>
</template>

<script>
import Search from '@/components/search/Search'
import PermanenceModal from '@/components/iam/PermanenceModal'
import ApplicationStatus from '@/components/iam/ApplicationStatus'
import NotificationDropdown from '@/components/iam/NotificationDropdown'
import MailClient from '@/components/iam/mailclient/Modal'
import { mapActions } from 'vuex'

export default {
  name: 'TopBar',
  components: {
    ApplicationStatus,
    Search,
    MailClient,
    PermanenceModal,
    NotificationDropdown
  },
  methods: {
    ...mapActions('account', ['logout']),

    showPermanenceModal () {
      this.$refs.permanenceModal.show()
    },
    showMailClient () {
      this.$refs.mailClient.show()
    }
  }
}
</script>

<style scoped>
  .navbar {
    position: static;
  }
</style>
