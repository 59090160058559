<template>
  <div
    :data-type="context.type"
    :class="[
      context.classes.element,
      `formulate-input-element formulate-input-element--${context.type} tw-relative`
    ]"
  >
    <FormulateSlot
      name="prefix"
      :context="context"
    >
      <component
        v-if="context.slotComponents.prefix"
        :is="context.slotComponents.prefix"
        :context="context"
      />
    </FormulateSlot>
    <input
      v-model="search"
      type="text"
      v-bind="context.attributes"
      autocomplete="do-not-autofill"
      class="tw-pr-9"
      @focus="onFocus"
      @input="debouncedLoadResults"
      @keydown.enter.prevent="onEnter"
      @keydown.tab.exact="onEnter"
      @keydown.esc.stop="onEsc"
      @keydown.shift.tab.exact="onEsc"
      @keydown.down.prevent="onArrowDown"
      @keydown.up.prevent="onArrowUp"
      @blur="context.blurHandler"
    />
    <button
      v-if="!hideClearButton && context.hasValue && !multiple && !isDisabled"
      type="button"
      title="Clear"
      class="tw-p-2 tw-absolute tw-right-0 tw-inset-y-0 focus:tw-border focus:tw-border-tg-color"
      @click.stop="setResult()"
    >
      <i class="tw-px-1 tw-py-0.5 tw-bg-gray-e9 tw-rounded-full fa fa-times" />
    </button>
    <ul
      v-show="showResults"
      aria-label="results"
      tabindex="-1"
      class="tw-text-sm tw-absolute tw-top-12 tw-max-h-48 tw-w-full tw-bg-white tw-rounded-md tw-overflow-y-scroll tw-shadow-card tw-z-20"
      :style="resultsPosition"
    >
      <li v-if="isLoading" key="loading" class="tw-p-2">{{ autocomplete.textLoading }}...</li>
      <!-- Important to use @click.stop because we don't want the event to be bubbling up and calling onEsc event. -->
      <li
        v-for="(result, index) in results"
        v-else-if="results.length"
        :key="`result_${index}`"
        :class="[
          'tw-px-2 tw-py-1 tw-cursor-pointer hover:tw-bg-gray-e9',
          { 'tw-bg-gray-e9': index === arrowCounter }
        ]"
        @mouseenter="arrowCounter = index"
        @click.stop="setResult(result)"
      >
        <div class="tw-flex tw-flex-row">
          <div class="tw-flex-grow">
            <slot name="result">
              <component :is="autocomplete.optionRender || 'div'" :value="result">
                {{ getDisplayValue(result) }}
              </component>
            </slot>
          </div>
          <i
            v-if="multiple && findSelection(result)"
            :aria-checked="true"
            class="fas fa-check-circle tw-my-auto tw-text-success"
          />
        </div>
      </li>
      <li v-else key="error" class="tw-p-2">
        {{ error ? error : autocomplete.textNoResultsFound }}.
      </li>
    </ul>
    <details
      v-show="multiple && multipleSelections.length"
      :data-selections-collapsible="!!multipleSelections.length"
      class="tw-mt-1"
    >
      <summary>
        <!-- We use the w 93% to prevent the arrow from disappearing, it's a hack -->
        <div class="tw-text-xs tw-inline-flex tw-flex-row tw-justify-between tw-max-w-[93%] tw-w-full">
          <div class="link tw-font-semibold tw-inline-flex tw-max-w-[90%]">
            <span class="tw-truncate tw-w-full tw-block">
              {{ getSelectionsLabel(multipleSelections[0]) }}
            </span>
            <span v-if="multipleSelections.length > 1" class="tw-ml-1">
              +{{ multipleSelections.length - 1 }}
            </span>
          </div>
          <button
            type="button"
            title="Selectie wissen"
            class="tw-my-auto tw-font-semibold hover:tw-opacity-80 tw-inline-block"
            @click="clearAll"
          >
            <i class="fas fa-times-circle" />
          </button>
        </div>
      </summary>
      <ul
        aria-label="selections"
        class="tw-mt-1 tw-absolute tw-bg-white tw-shadow-card tw-rounded tw-px-1 tw-py-0.5 tw-z-10 tw-max-h-40 tw-overflow-y-auto tw-w-full"
      >
        <transition-group name="fade" mode="out-in">
          <template v-for="(selection, index) in multipleSelections">
            <!-- list items need to be in the DOM for accessibility tools to work. -->
            <li
              v-show="selection.id"
              :key="`selection_${selection.id}`"
              class="tw-flex tw-gap-0.5 tw-items-start tw-my-1"
            >
              <button
                type="button"
                title="Verwijderen"
                aria-label="remove selection"
                tabindex="0"
                class="hover:tw-opacity-80 focus:tw-ring-2 focus:tw-ring-primary"
                @click.stop="removeSelection(index)"
              >
                <i class="fas fa-times-circle tw-mr-0.5" />
              </button>
              <span
                :title="getDisplayValue(selection)"
                class="tw-font-semibold"
              >
                {{ getDisplayValue(selection) }}
              </span>
            </li>
          </template>
        </transition-group>
      </ul>
    </details>
  </div>
</template>

<script>
import { getAutoCompleteType } from '@/forms/autoCompleteTypes'
import debounce from 'lodash/debounce'

export default {
  name: 'BaseInputAutoComplete',
  props: {
    context: {
      type: Object,
      required: true
    },
    autoCompleteType: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default () {
        return {}
      }
    },
    unpackChildren: {
      type: Function,
      default: null
    },
    showResultsOnFocus: {
      // Shows all the results, after a user clicks on the input.
      type: Boolean,
      default: false
    },
    stayOpenAfterSelection: {
      // Doesn't close the results list after selecting an item, handy for multiple selections.
      type: Boolean,
      default: false
    },
    hideClearButton: {
      // Hides the clear button for single selection autocompletes, it's not needed in case of group repeatables
      type: Boolean,
      default: false
    },
    resultsPositionFixed: {
      // Controls whether results list uses fixed positioning with calculated coordinates
      // Used for tricky situations with modals where the content scrolls and makes it difficult to see the results unless user scrolls
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showResults: false,
      results: [],
      error: '',
      search: '',
      isLoading: false,
      arrowCounter: -1,
      multipleSelections: [],
      resultsPosition: {}
    }
  },
  computed: {
    model: {
      get () {
        return this.context.model
      },
      set (val) {
        this.context.model = val
      }
    },
    autocomplete () {
      return getAutoCompleteType(this.autoCompleteType)
    },
    isDisabled () {
      const disabled = this.context.attributes?.disabled
      return disabled === '' || disabled === true || disabled === 'true'
    }
  },
  watch: {
    model (value) {
      if (this.multiple && Array.isArray(value)) this.multipleSelections = [...value]
      this.search = this.multiple ? '' : this.getDisplayValue(value)
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
    // Find the nearest scrollable parent element that can affect positioning
    const scrollableParent = this.findScrollableParent(this.$el)

    // Only add scroll listener if:
    // 1. We found a scrollable parent
    // 2. It's not the document itself (which doesn't need special handling)
    if (scrollableParent && scrollableParent !== document.documentElement) {
      // Add scroll listener to update dropdown position when parent scrolls
      scrollableParent.addEventListener('scroll', this.updateResultsPosition)
    }
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
    // Find the same scrollable parent to remove the scroll listener
    const scrollableParent = this.findScrollableParent(this.$el)
    if (scrollableParent && scrollableParent !== document.documentElement) {
      // Clean up scroll listener to prevent memory leaks
      scrollableParent.removeEventListener('scroll', this.updateResultsPosition)
    }
  },
  methods: {
    debouncedLoadResults: debounce(
      function (event) {
        this.loadResults(event.target.value)
      },
      1000
    ),

    loadResults (query) {
      this.isLoading = true
      const trimmedQuery = query.trim()

      const params = { query: trimmedQuery, ...this.params }
      if (this.multiple) {
        params.include_children = 1
        params.include_custom = 1
        params.serialize_children = 0
      }

      this.autocomplete.fetchResults({ params })
        .then(response => {
          this.results = response.data[this.autocomplete.optionsKey || 'results']
        })
        .catch(error => {
          this.results = []
          this.error = error.detail
        })
        .finally(() => {
          this.isLoading = false
          this.showResults = true
          this.arrowCounter = -1
          // Update position after results are loaded
          this.$nextTick(() => {
            this.updateResultsPosition()
          })
        })
    },

    async setResult (result = '') {
      if (result && this.multiple) {
        let selections = [...this.multipleSelections]
        const alreadyExistsIdx = selections.findIndex(selection => selection.id === result.id)

        if (this.unpackChildren) {
          const children = await this.unpackChildren(result)

          if (alreadyExistsIdx > -1) {
            // Item already exists as index is not negative
            const childIds = children.map(child => child.id)
            selections = selections.filter(selection => !childIds.includes(selection.id))
          } else {
            selections.push(...children)
          }
        } else {
          if (alreadyExistsIdx > -1) {
            // Removes the already existing selection.
            selections.splice(alreadyExistsIdx, 1)
          } else {
            selections.push(result)
          }
        }
        const uniqueSelections = selections.filter((selection, index, array) => array.findIndex(el => (el.id === selection.id)) === index)
        this.multipleSelections = uniqueSelections
      }

      this.model = this.multiple ? this.multipleSelections : result
      if (!this.stayOpenAfterSelection) {
        this.results = []
        this.showResults = false
      }

      const { selectionCallback } = this.autocomplete
      if (selectionCallback) {
        const response = await selectionCallback(result)
        this.context.rootEmit('callback', response)
        return response
      }
    },
    findSelection (result) {
      return this.multipleSelections.find(selection => result.id === selection.id)
    },

    getDisplayValue (value) {
      if (!value) return ''
      if (typeof value === 'object') return this.autocomplete.displayFormat(value)
      return this.autocomplete.allowText ? value : ''
    },
    getSelectionsLabel (value) {
      const displayMethod = this.autocomplete.selectionsLabel || this.getDisplayValue
      return displayMethod(value)
    },

    removeSelection (index) {
      this.multipleSelections.splice(index, 1)
      return this.setResult()
    },

    clearAll () {
      this.multipleSelections = []
      return this.setResult()
    },

    handleClickOutside (evt) {
      if (!this.$el.contains(evt.target)) {
        this.onEsc()
      }
    },
    onEsc () {
      if (!this.search?.length) this.setResult()
      else if (!this.multiple) {
        if (!this.model && this.autocomplete.allowText) this.setResult(this.search)
        else this.search = this.getDisplayValue(this.model)
      }
      this.showResults = false
      this.arrowCounter = -1
    },
    onArrowDown () {
      if (this.arrowCounter < this.results.length - 1) {
        this.arrowCounter = this.arrowCounter + 1
      }
    },
    onArrowUp () {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1
      }
    },
    onEnter () {
      if (this.autocomplete.allowText) this.setResult(this.search)

      const result = this.results[this.arrowCounter]
      if (result) this.setResult(result)
    },
    onFocus (event) {
      return this.showResultsOnFocus && !this.showResults
        ? this.loadResults(event.target.value)
        : event.preventDefault()
    },

    /**
     * Updates the position of the results dropdown when using fixed positioning
     * This is particularly useful when the autocomplete is inside a modal or scrollable container
     * to ensure the dropdown stays aligned with the input field
     */
    updateResultsPosition () {
      if (!this.resultsPositionFixed) return false

      const inputEl = this.$el.querySelector('input')
      // Get the input element's position relative to the viewport
      const rect = inputEl.getBoundingClientRect()

      // Set fixed positioning for the results dropdown
      // This ensures it stays aligned with input even when scrolling
      this.resultsPosition = {
        position: 'fixed',
        top: `${rect.bottom}px`, // Position below the input
        left: `${rect.left}px`, // Align with input's left edge
        width: `${rect.width}px` // Match input's width
      }
    },

    /**
     * Finds the nearest parent element that has scrollable content
     * Used to attach scroll event listeners for updating dropdown position
     * @param {HTMLElement} element - The starting element to search from
     * @returns {HTMLElement|null} The nearest scrollable parent or null if none found
     */
    findScrollableParent (element) {
      let parent = element.parentElement
      while (parent) {
        // Check if element has overflow content (content larger than container)
        const hasScrollableContent = parent.scrollHeight > parent.clientHeight
        // Check if element allows scrolling (overflow not set to 'visible')
        const isScrollable = getComputedStyle(parent).overflow !== 'visible'

        if (hasScrollableContent && isScrollable) {
          return parent
        }
        parent = parent.parentElement
      }
      return null
    }
  }
}
</script>
