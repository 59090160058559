<template>
  <div id="app">
    <Mia v-if="enableMia" />
    <!--
      We check if the user is an IAM user by checking if they have a collaborator linked to the user object,
      if there is no collaborator, we only want to show a plain App template and hide other parts.
     -->
    <div v-show="collaborator">
      <Controller />
      <Navigation />
    </div>

    <div :id="collaborator ? 'page-wrapper' : 'access-denied'" :class="{ 'gray-bg': collaborator }">
      <div v-if="collaborator">
        <TopBar />
      </div>

      <transition name="fade" mode="out-in">
        <!-- This will only re-render when there is a change to the "id" param of the route. -->
        <component :is="$route.meta.layout || 'div'" :key="$route.params.id">
          <router-view />
        </component>
      </transition>

      <div v-if="collaborator" class="tw-w-1/4 tw-absolute tw-bottom-0 tw-right-0 tw-text-right tw-text-xs tw-p-4">
        versie {{ version }}
      </div>
    </div>
  </div>
</template>

<script>
import Mia from '@/components/Mia'
import TopBar from '@/components/TopBar'
import Navigation from '@/components/Navigation'
import Controller from '@/components/iam/Controller'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Mia,
    TopBar,
    Navigation,
    Controller
  },
  computed: {
    ...mapGetters('account', ['collaborator']),

    version () {
      return process.env.VUE_APP_VERSION
    },
    enableMia () {
      return process.env.VUE_APP_ENVIRONMENT !== 'prod'
    }
  },
  methods: {
    ...mapActions('account', ['loadUser'])
  }
}
</script>
