import { httpClient } from './axiosClients'
import { commaSerializer } from './paramSerializers'

// We provide an empty object as default value to payload because we de-structure it later on
export function getContacts (payload = {}) {
  const { url = '/api/contacts', params } = payload
  return httpClient.get(url, {
    params,
    paramsSerializer: commaSerializer
  })
}

export function getExactDuplicateContacts (payload = {}) {
  const { url = '/api/contacts/exact-duplicates', params } = payload
  return httpClient.get(url, { params })
}

export function getContactById (contactId) {
  return httpClient.get(`/api/contacts/${contactId}`)
}

export function getContactVies (contactId) {
  return httpClient.get(`/api/contacts/${contactId}/vies`)
}

export function createContact (contact) {
  return httpClient.post('/api/contacts', contact)
}

export function updateContact (contact) {
  return httpClient.patch(`/api/contacts/${contact.id}`, contact)
}

export function getContactFiles (payload = {}) {
  const { contactId, url = `/api/contacts/${contactId}/files`, params } = payload
  return httpClient.get(url, { params })
}

export function getContactFileIds (contactId) {
  return httpClient.get(`/api/contacts/${contactId}/files/ids`)
}

export function deleteContactFile (contactId, fileId) {
  return httpClient.delete(`/api/contacts/${contactId}/files/${fileId}`)
}

export function getContactGroups (payload = {}) {
  const { url = '/api/contacts/groups', params } = payload
  return httpClient.get(url, { params })
}

export function getContactsGroupById (groupId) {
  return httpClient.get(`/api/contacts/groups/${groupId}`)
}

export function createContactsGroup (group) {
  return httpClient.post('/api/contacts/groups', group)
}

export function updateContactsGroup (group) {
  return httpClient.patch(`/api/contacts/groups/${group.id}`, group)
}

export function addContactsToGroup (payload) {
  return httpClient.post('/api/contacts/add-contacts-to-groups', payload)
}

export function deleteContactsGroup (groupId) {
  return httpClient.delete(`/api/contacts/groups/${groupId}`)
}

export function getContactLinks (contactId) {
  return httpClient.get(`/api/contacts/link?contact=${contactId}`)
}

export function createContactsLink (link) {
  return httpClient.post('/api/contacts/link', link)
}

export function updateContactsLink (link) {
  return httpClient.patch(`/api/contacts/link/${link.id}`, link)
}

export function deleteContactsFromGroup (groupId, contacts) {
  return httpClient.delete(`/api/contacts/groups/${groupId}/remove/${contacts}`)
}

export function getContactsForGroup (payload = {}) {
  const { groupId, url = `/api/contacts/groups/${groupId}/contacts`, params } = payload
  return httpClient.get(url, { params })
}

export function getContactIdsForGroup (groupId) {
  return httpClient.get(`/api/contacts/groups/${groupId}/contacts/ids`)
}

export function getContactLinkedIdsForGroup (groupId) {
  return httpClient.get(`/api/contacts/groups/${groupId}/contacts/linked-contact-ids`)
}

export function getContactsByIds (params) {
  // This supports both contact_ids, hmodh_ids or match_ids in the payload, to fetch contact objects.
  // For less data (default), use display = 'minimal', for full data, use display = 'full'
  return httpClient.get('/api/contacts/by-ids', {
    params,
    paramsSerializer: commaSerializer
  })
}

export function exportContactGroup (payload) {
  return httpClient.post('/api/contacts/export/group', payload)
}

export function exportContactFilter (payload) {
  return httpClient.post('/api/contacts/export/filter', payload)
}

export function exportContactSelection (payload) {
  return httpClient.post('/api/contacts/export/selection', payload)
}

export function getContactExportStatus (jobId) {
  return httpClient.get(`/api/contacts/export/${jobId}/detail`)
}

export function setPasswordForContact (contactId, payload) {
  return httpClient.patch(`/api/contacts/${contactId}/portal`, payload)
}

export function getPortalUser (contactId) {
  return httpClient.get(`/api/contacts/${contactId}/portal`)
}

export function getContactCommunications (payload = {}) {
  const { contactId, url = `/api/v3/contacts/${contactId}/communications`, params } = payload
  return httpClient.get(url, { params })
}

export function createContactCommunication (contactId, payload) {
  return httpClient.post(`/api/v3/contacts/${contactId}/communications`, payload)
}

export function updateContactCommunication (contactId, communicationId, communication) {
  return httpClient.patch(`/api/v3/contacts/${contactId}/communications/${communicationId}`, communication)
}

export function deleteContactCommunication (contactId, communicationId) {
  return httpClient.delete(`/api/v3/contacts/${contactId}/communications/${communicationId}`)
}

export function getContactEntities (payload = {}) {
  const { contactId, url = `/api/contacts/${contactId}/entities`, params } = payload
  return httpClient.get(url, { params })
}

export function getContactRentals (payload = {}) {
  const { contactId, url = `/api/contacts/${contactId}/rentals`, params } = payload
  return httpClient.get(url, { params })
}

export function getContactDuplicates (payload = {}) {
  const { contactId, url = `/api/contacts/duplicates/${contactId}`, params } = payload
  return httpClient.get(url, { params })
}

export function getSubscribedMediums (contactId) {
  return httpClient.get(`/api/contacts/${contactId}/subscribed-mediums`)
}

export function getMediumIds () {
  return httpClient.get('/api/subscriptions/medium-ids')
}

export function updateSubscribedMediums (contactId, payload) {
  return httpClient.put(`/api/contacts/${contactId}/subscribed-mediums`, payload)
}

export function getCategories () {
  return httpClient.get('api/subscriptions/categories')
}

export function stopCommunication (contactId) {
  return httpClient.post(`/api/contacts/${contactId}/stop-communication`)
}

export function getMergeContactsInitialData (params) {
  return httpClient.get('/api/contacts/merge/initial-data', { params, paramsSerializer: commaSerializer })
}

export function mergeContacts (payload) {
  return httpClient.post('/api/contacts/merge', payload)
}

export function getBirthPlaces ({ params }) {
  return httpClient.get('/api/contacts/birthplaces', { params })
}

export function createBirthPlace (payload) {
  return httpClient.post('/api/contacts/birthplaces', payload)
}

export function getContactPortalLogins (payload = {}) {
  const { contactId, url = `/api/contacts/${contactId}/logins`, params } = payload
  return httpClient.get(url, { params })
}

export function getPropertiesForContact (contactId, params) {
  return httpClient.get(`/api/v3/properties-for-contact/${contactId}`, { params })
}

export function activatePortal (payload) {
  return httpClient.post('/api/contact-activation/start', payload)
}

export function getActivationRecords (params) {
  return httpClient.get('/api/activation-records', { params })
}

export function resendPortalActivationLink (payload) {
  return httpClient.post('/api/contact-activation/resend-activation-link', payload)
}

export function getContactLinkTypes () {
  return httpClient.get('/api/contacts/link-types')
}

export function getContactTags (payload = {}) {
  const { url = '/api/contact-tags', params } = payload
  return httpClient.get(url, { params })
}

export function getContactTagsOverview (payload = {}) {
  const { url = '/api/contact-tags-overview', params } = payload
  return httpClient.get(url, { params })
}

export function getContactTagColors () {
  return httpClient.get('/api/contact-tag-colors')
}

export function getContactTagsForContactId (contactId, params) {
  return httpClient.get(`/api/contacts/${contactId}/tags`, { params })
}

export function createContactTag (payload) {
  return httpClient.post('/api/contact-tags', payload)
}

export function addTagToContact (contactId, payload) {
  return httpClient.post(`/api/contacts/${contactId}/tags`, payload)
}

export function updateContactTag (contactId, tagId, payload) {
  return httpClient.patch(`/api/contacts/${contactId}/tags/${tagId}`, payload)
}

export function bulkAssignTagsToContacts (payload) {
  return httpClient.post('/api/contact-tags/assign-multiple', payload)
}

export function getContactTagDetails (tagId) {
  return httpClient.get(`/api/contact-tags/${tagId}`)
}

export function getContactsByTagId (payload = {}) {
  const { tagId, url = `/api/contact-tags/${tagId}/contacts`, params } = payload
  return httpClient.get(url, { params })
}

export function getSavedContactFilters (params) {
  return httpClient.get('/api/saved-contact-filters-for-user', { params })
}

export function saveContactFilters (payload) {
  return httpClient.post('/api/saved-contact-filters-for-user', payload)
}

export function updateContactFilters (filterId, payload) {
  return httpClient.put(`/api/saved-contact-filters-for-user/${filterId}`, payload)
}

export function deleteContactFilter (filterId) {
  return httpClient.delete(`/api/saved-contact-filters-for-user/${filterId}`)
}

export function exportContactsAsCsv (payload) {
  return httpClient.post('/api/contacts/export/as-csv', payload)
}

export function pollExportJob (jobId) {
  return httpClient.get(`/api/contacts/export/${jobId}/detail`)
}

export function getContactTagsHistory (contactId) {
  return httpClient.get(`/api/contacts/${contactId}/tags/history`)
}

export function calculateContactScore (contactId) {
  return httpClient.post(`/api/contacts/${contactId}/score`)
}

export function getContactNoShowsCount (contactId) {
  return httpClient.get(`/api/contacts/${contactId}/no-shows-count`)
}

export function getContactBids (payload = {}) {
  const { contactId, url = `/api/contacts/${contactId}/bids`, params } = payload
  return httpClient.get(url, { params })
}
