import { httpClient } from './axiosClients'
import { commaSerializer, repeatSerializer } from './paramSerializers'

export function getProperty (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}`)
}

export function createProperty (payload) {
  return httpClient.post('/api/v3/property', payload)
}

export function updateProperty (propertyId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}`, payload)
}

// We provide an empty object as default value to payload because we de-structure it later on
export function getPropertiesGroup (payload = {}) {
  const { url = '/api/v3/property-groups', params } = payload
  return httpClient.get(url, { params })
}

export function deletePropertyGroup (groupId) {
  return httpClient.delete(`/api/v3/property-groups/${groupId}`)
}

export function updatePropertyGroups (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/groups/update`, payload)
}

export function addPropertiesToGroup (groupId, payload) {
  return httpClient.post(`/api/v3/property-groups/${groupId}/properties/add`, payload)
}

export function deletePropertiesFromGroup (groupId, properties) {
  return httpClient.post(`/api/v3/property-groups/${groupId}/properties/remove`, properties)
}

export function getPropertyOffices (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/offices`)
}

export function updatePropertyOffices (propertyId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/offices/edit`, payload)
}

export function updatePropertyCollaborators (propertyId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/collaborators/edit`, payload)
}

export function updatePropertyCollaborator (propertyId, collaboratorId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/collaborators/${collaboratorId}`, payload)
}

export function makePropertyOfficePrimary (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/offices/set-primary`, payload)
}

export function getPropertyOwners (propertyId, params) {
  return httpClient.get(`/api/v3/property/${propertyId}/owners`, { params })
}

export function getPropertyRenters (propertyId, params) {
  return httpClient.get(`/api/v3/property/${propertyId}/renters`, { params })
}

export function getPropertyBuyers (propertyId, params) {
  return httpClient.get(`/api/v3/property/${propertyId}/buyers`, { params })
}

export function updatePropertyBuyers (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/buyers`, payload)
}

export function updatePropertyRenters (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/renters`, payload)
}

export function updatePropertyOwners (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/owners`, payload)
}

export function getPropertyNotaryOfBuyers (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/notary/buyers`)
}

export function updatePropertyNotaryOfBuyers (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/notary/buyers`, payload)
}

export function getPropertyNotaryOfOwners (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/notary/owners`)
}

export function updatePropertyNotaryOfOwners (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/notary/owners`, payload)
}

export function getPropertyCollaborators (payload) {
  const { propertyId, url = `/api/v3/property/${propertyId}/collaborators`, params } = payload
  return httpClient.get(url, { params })
}

export function getPropertyEnergy (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/energy`)
}

export function getPropertyPrintData (payload) {
  const { propertyId, url = `/api/v3/property/${propertyId}/print-data`, params } = payload
  // This API requires repeate serialisation, eg: languages=nl&languages=fr
  return httpClient.get(url, {
    params,
    paramsSerializer: repeatSerializer
  })
}

export function getPropertyRooms (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/rooms`)
}

export function updatePropertyRoom (propertyId, propertyRoomId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/rooms/${propertyRoomId}`, payload)
}

export function addPropertyRoom (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/rooms`, payload)
}

export function deletePropertyRoom (propertyId, roomId) {
  return httpClient.delete(`/api/v3/property/${propertyId}/rooms/${roomId}`)
}

// We provide an empty object as default value to payload because we de-structure it later on
export function getPropertyFiles (payload = {}) {
  const { propertyId, url = `/api/v3/property/${propertyId}/files`, params } = payload
  return httpClient.get(url, { params })
}

export function createPropertyFile (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/files`, payload)
}

export function createPropertyFilesBulk (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/create-bulk`, payload)
}

export function uploadFile (propertyId, payload) {
  return httpClient.post(
    `/api/v3/property/${propertyId}/files/upload`,
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export function uploadMedia (propertyId, payload) {
  return httpClient.post(
    `/api/v3/property/${propertyId}/media`,
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export function getPropertyFilesFromIds (propertyId, params) {
  return httpClient.get(`/api/v3/property/${propertyId}/files/file-data`, {
    params,
    paramsSerializer: commaSerializer
  })
}

export function getPropertyLegalNotices (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/legal-notices`)
}

export function downloadPropertyFiles (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/files/download`, payload)
}

export function pollPropertyDownload (propertyId, jobId) {
  return httpClient.get(`/api/v3/property/${propertyId}/files/download/${jobId}`)
}

export function getPropertyFilesAllIds (payload) {
  const { propertyId, url = `/api/v3/property/${propertyId}/files/ids`, params } = payload
  return httpClient.get(url, { params })
}

export function getPropertyFileById (propertyId, fileId) {
  return httpClient.get(`/api/v3/property/${propertyId}/files/${fileId}`)
}

export function updatePropertyFile (propertyId, file) {
  return httpClient.patch(`/api/v3/property/${propertyId}/files/${file.id}`, file)
}

export function deletePropertyFile (propertyId, fileId) {
  return httpClient.delete(`/api/v3/property/${propertyId}/files/${fileId}`)
}

export function deletePropertyFilesMultiple (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/files/delete`, payload)
}

export function getPropertyFileFolders (propertyId, params = {}) {
  return httpClient.get(
    `/api/v3/property/${propertyId}/folders`,
    {
      params: {
        ...params,
        page_size: 1000
      }
    }
  )
}

export function createDefaultPropertyFolderStructure (propertyId) {
  return httpClient.post(`/api/v3/property/${propertyId}/create-default-folder-structure`)
}

export function createPropertyFileFolder (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/folders`, payload)
}

export function deletePropertyFileFolder (propertyId, folderId) {
  return httpClient.delete(`/api/v3/property/${propertyId}/folders/${folderId}`)
}

export function editPropertyFileFolder (propertyId, payload) {
  return httpClient.put(`/api/v3/property/${propertyId}/folders/${payload.id}`, payload)
}

export function addPropertyFilesToFolder (propertyId, folderId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/folders/${folderId}/files/add`, payload)
}

export function removePropertyFilesFromFolder (propertyId, folderId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/folders/${folderId}/files/remove`, payload)
}

export function getStickerTypes () {
  return httpClient.get('/api/v3/property/sticker-types')
}

export function getPropertyStickers (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/stickers`)
}

export function createPropertySticker (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/stickers`, payload)
}

export function deletePropertySticker (propertyId, stickerId) {
  return httpClient.delete(`/api/v3/property/${propertyId}/stickers/${stickerId}`)
}

export function getPropertyContentBlocks (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/content_blocks`)
}

export function createPropertyContentBlock (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/content_blocks`, payload)
}

export function updatePropertyContentBlock (contentBlockId, payload) {
  return httpClient.patch(`/api/v3/property/content_blocks/${contentBlockId}`, payload)
}

export function deletePropertyContentBlock (contentBlockId) {
  return httpClient.delete(`/api/v3/property/content_blocks/${contentBlockId}`)
}

export function getPublicationActionTypes (payload = {}) {
  const { url = '/api/v3/publication-action-types', params } = payload
  return httpClient.get(url, { params })
}

export function getPropertyPublicationActions (payload = {}) {
  const { propertyId, url = `/api/v3/property/${propertyId}/publication-actions`, params } = payload
  return httpClient.get(url, { params })
}

export function createPropertyPublicationAction (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/publication-actions`, payload)
}

export function updatePropertyPublicationAction (propertyId, propertyPublicationActionId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/publication-actions/${propertyPublicationActionId}`, payload)
}

export function deletePropertyPublicationAction (propertyId, propertyPublicationActionId) {
  return httpClient.delete(`/api/v3/property/${propertyId}/publication-actions/${propertyPublicationActionId}`)
}

export function publishWizardComplete (propertyId) {
  return httpClient.post(`/api/v3/property/${propertyId}/publish-wizard-complete`)
}

export function getPropertyDescriptions (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/descriptions`)
}

export function createPropertyDescriptions (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/descriptions`, payload)
}

export function updatePropertyDescriptionsBulk (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/descriptions/bulk`, payload)
}

export function updatePropertyContentBlocksOrder (propertyId, payload) {
  return httpClient.put(`/api/v3/property/${propertyId}/content_blocks/order`, payload)
}

export function getPropertyPictures (payload) {
  const { propertyId, url = `/api/v3/property/${propertyId}/pictures`, params } = payload
  return httpClient.get(url, { params })
}

export function getPropertyMainPicture (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/main-picture`)
}

export function updatePropertyPicturesBulk (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/pictures/bulk`, payload)
}

export function optimizePropertyPictures (propertyId) {
  return httpClient.post(`/api/v3/property/${propertyId}/pictures/optimize`)
}

export function togglePropertyPicturePrivacy (propertyId, pictureId) {
  return httpClient.post(`/api/v3/property/${propertyId}/pictures/${pictureId}/toggle-privacy-status`)
}

export function pollPictureOptimizationStatus (jobId) {
  return httpClient.get(`/api/v3/picture-optimization/status/${jobId}`)
}

export function pasteConceptStickerOnPicture (propertyId, pictureId) {
  return httpClient.post(`/api/v3/property/${propertyId}/pictures/${pictureId}/paste-concept-sticker`)
}

export function getPropertyFileAsTemplate (propertyId, fileId) {
  return httpClient.get(`/api/v3/property/${propertyId}/files/${fileId}/mail-template`)
}

export function propertyFilesDirectDownload (propertyId, documentId) {
  return window.open(`/api/v3/property/${propertyId}/files/${documentId}/direct-download`, '_blank')
}

export function getPropertyCommunications (payload = {}) {
  const { propertyId, url = `/api/v3/property/${propertyId}/communications`, params } = payload
  return httpClient.get(url, { params })
}

export function createPropertyCommunication (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/communications`, payload)
}

export function updatePropertyCommunication (propertyId, communicationId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/communications/${communicationId}`, payload)
}

export function deletePropertyCommunication (propertyId, communicationId) {
  return httpClient.delete(`/api/v3/property/${propertyId}/communications/${communicationId}`)
}

export function getPropertyListDataByIds (params) {
  return httpClient.get('/api/v3/property/property-list-data-by-ids', {
    params,
    paramsSerializer: commaSerializer
  })
}

export function getPropertyByIds (params) {
  return httpClient.get('/api/v3/property/by-ids', {
    params,
    paramsSerializer: commaSerializer
  })
}

export function getProperties (payload = {}, page_size = 1000) {
  const { url = `/api/v3/property?page_size=${page_size}`, params } = payload
  return httpClient.get(url, {
    params,
    paramsSerializer: commaSerializer
  })
}

export function getPropertyIds (payload) {
  const { params } = payload
  return httpClient.get('/api/v3/property/id-list', {
    params,
    paramsSerializer: commaSerializer
  })
}

export function getPropertyPortalPublications (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/portal-publications`)
}

export function createPropertyPortalPublication (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/portal-publications`, payload)
}

export function updatePropertyPortalPublication (propertyId, publicationId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/portal-publications/${publicationId}`, payload)
}

export function togglePropertyPublicationBufferPeriod (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/portal-publications/toggle-buffer-period`, payload)
}

export function getPortals (sneakPeek = false) {
  return httpClient.get('/api/v3/publication-portals', {
    params: {
      sneak_peek: sneakPeek
    }
  })
}

export function getPropertyStatistics (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/statistics`)
}

export function getPropertyAddressableContacts (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/addressable-contacts`)
}

export function labelPictures (propertyId) {
  return httpClient.post(`/api/v3/property/${propertyId}/label-pictures`)
}

export function getEventTypes () {
  return httpClient.get('/api/v3/property/event-types')
}

export function getPropertyEvents (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/events`)
}

export function createPropertyEvent (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/events`, payload)
}

export function deletePropertyEvent (propertyId, eventId) {
  return httpClient.delete(`/api/v3/property/${propertyId}/events/${eventId}`)
}

export function validatePropertyReference (reference) {
  return httpClient.get(`/api/v3/property/validate-reference?reference=${reference}`)
}

export function exportPropertyModules (payload) {
  return httpClient.post('/api/v3/property/export-modules', payload)
}

export function getPropertyTechnical (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/technical`)
}

export function getPropertyCommercial (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/commercial`)
}

export function getPropertyComfort (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/comfort`)
}

export function getPropertyFinancial (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/financial`)
}

export function getPropertyLegal (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/legal`)
}

export function getPropertyConcept (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/concept`)
}

export function updatePropertyCommercial (propertyId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/commercial`, payload)
}

export function updatePropertyComfort (propertyId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/comfort`, payload)
}

export function updatePropertyEnergy (propertyId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/energy`, payload)
}

export function updatePropertyFinancial (propertyId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/financial`, payload)
}

export function updatePropertyLegal (propertyId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/legal`, payload)
}

export function updatePropertyTechnical (propertyId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/technical`, payload)
}

export function updatePropertyConcept (propertyId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/concept`, payload)
}

export function getPropertyMarketing (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/marketing`)
}

export function updatePropertyMarketing (propertyId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/marketing`, payload)
}

export function setPropertyDeleted (propertyId) {
  return httpClient.post(`/api/v3/property/${propertyId}/set-deleted`)
}

export function createPropertyStrengths (propertyId) {
  return httpClient.post(`/api/v3/property/${propertyId}/strengths`)
}

export function getHistoryForProperty (payload = {}) {
  const { propertyId, url = `/api/v3/property/${propertyId}/history` } = payload
  return httpClient.get(url)
}

export function unlinkPropertyFromHistory (payload) {
  return httpClient.post('/api/v3/property/history/unlink', payload)
}

export function linkPropertyToHistory (property_id_to_link_to, payload) {
  return httpClient.post(`/api/v3/property/${property_id_to_link_to}/history/link`, payload)
}

export function searchProperties ({ params }) {
  return httpClient.get('/api/v3/property', { params })
}

export function storeGutenborgFile (propertyId, payload) {
  // https://dewaele.atlassian.net/browse/DEW-5758
  return httpClient.post(`/api/v3/property/${propertyId}/files/store-gutenborg-file`, payload)
}
export function getInvestmentGridDefaultValues (propertyId) {
  return httpClient.get(`/api/properties/${propertyId}/investment-grid/default-values`)
}

export function calculateInvestmentGrid (propertyId, payload) {
  return httpClient.post(`/api/properties/${propertyId}/investment-grid/calculate`, payload)
}

export function calculateInvestmentReportData (propertyId, payload) {
  return httpClient.post(`/api/properties/${propertyId}/investment-report/data`, payload)
}

export function storeInvestmentReportAsLeadFile (propertyId, payload) {
  return httpClient.post(`/api/properties/${propertyId}/investment-report/store`, payload)
}

export function getPropertyDossierData (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/dossier-preparation`)
}

export function postPropertyDossierPreparation (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/dossier-preparation`, payload)
}

export function getPropertyPrePublishChecks (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/pre-publish-checks`)
}

export function getPropertyDeedData (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/deed`)
}

export function postPropertyDeed (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/deed`, payload)
}

export function getPropertyGeolocation (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/geolocation`)
}

export function updatePropertyGeolocation (propertyId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/geolocation`, payload)
}

export function getPropertyAuditTrail (payload) {
  const { propertyId, url = `/api/v3/property/${propertyId}/audit-trail`, params } = payload
  return httpClient.get(url, { params })
}

export function transferEntities (payload) {
  return httpClient.post('/api/v3/entity-transfer', payload)
}

export function registerEntityFilesAccessed (entityTypeStr, fileIds) {
  return httpClient.post(`/api/v3/${entityTypeStr}/register-files-accessed`, { file_ids: fileIds })
}

export function sendPropertyPicturesAvailableEmail (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/send-pictures-available-email`, payload)
}

export function duplicateProperty (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/duplicate`, payload)
}

export function getPropertyInvoiceContacts (propertyId, params) {
  return httpClient.get(`/api/properties/${propertyId}/entity-invoice-recipient-groupings`, { params })
}

export function updatePropertyInvoiceContacts (propertyId, payload) {
  return httpClient.post(`/api/properties/${propertyId}/entity-invoice-recipient-groupings`, payload)
}

export function startPropertyInvoice (propertyId, payload) {
  return httpClient.post(`/api/properties/${propertyId}/invoicing/start`, payload)
}

export function getPropertyInvoicePreview (propertyId, payload) {
  // POST Request returning data without creating any server-side resources
  // A POST request is recommended since the amount of parameters to be sent to the BE is expected to be large
  return httpClient.post(`/api/properties/${propertyId}/invoicing/preview`, payload)
}

export function getPdfInvoices (params) {
  return httpClient.get('/api/pdf-invoices', {
    params,
    paramsSerializer: commaSerializer
  })
}

export function generatePropertyProformaInvoices (propertyId, payload) {
  return httpClient.post(`/api/properties/${propertyId}/invoicing/proforma`, payload)
}

export function createPropertyProformaInvoice (propertyId, payload) {
  return httpClient.post(`/api/properties/${propertyId}/proforma-invoices`, payload)
}

export function uploadPropertyPicture (propertyId, payload) {
  return httpClient.post(
    `/api/v3/property/${propertyId}/pictures/upload`,
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export function getPropertyBids (payload) {
  const { propertyId, url = `/api/properties/${propertyId}/bids`, params } = payload
  return httpClient.get(url, { params })
}

export function createPropertyBid (propertyId, payload) {
  return httpClient.post(`/api/properties/${propertyId}/bids`, payload)
}

export function deletePropertyBid (propertyId, bidId) {
  return httpClient.delete(`/api/properties/${propertyId}/bids/${bidId}`)
}

export function updatePropertyBid (propertyId, bidId, payload) {
  return httpClient.patch(`/api/properties/${propertyId}/bids/${bidId}`, payload)
}

export function acceptPropertyBid (propertyId, bidId, payload) {
  return httpClient.post(`/api/properties/${propertyId}/bids/${bidId}/accept`, payload)
}

export function getPropertySalesAgreement (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/sales-agreement`)
}

export function getPropertyRentalAgreement (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/rental-agreement`)
}

export function propertyRentalAgreementProcessing (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/rental-agreement`, payload)
}

export function cancelPropertyDossier (propertyId) {
  return httpClient.post(`/api/v3/property/${propertyId}/cancel-dossier`)
}

export function stopAllPublications (propertyId) {
  return httpClient.post(`/api/v3/property/${propertyId}/stop-publications`)
}

export function propertySalesAgreementProcessing (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/sales-agreement`, payload)
}

export function importOrganimmoProperties (payload) {
  return httpClient.post('/api/import-organimmo-properties', payload)
}

export function getEntityRoles () {
  return httpClient.get('/api/entity-roles')
}

export function getPropertyContacts (payload) {
  const { propertyId, url = `/api/v3/property/${propertyId}/contacts`, params } = payload
  return httpClient.get(url, { params })
}

export function createPropertyContact (propertyId, payload) {
  return httpClient.post(`/api/v3/property/${propertyId}/contacts`, payload)
}

export function updatePropertyContact (propertyId, propertyContactId, payload) {
  return httpClient.patch(`/api/v3/property/${propertyId}/contacts/${propertyContactId}`, payload)
}

export function deletePropertyContact (propertyId, propertyContactId) {
  return httpClient.delete(`/api/v3/property/${propertyId}/contacts/${propertyContactId}`)
}

export function getPropertyHoverable (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/hoverable`)
}

export function getRentalTerms (params) {
  return httpClient.get('/api/rental-terms', { params })
}

export function getSneakPeekChecks (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/sneak-peek-checks`)
}

export function getPropertyBiddingRounds (propertyId) {
  return httpClient.get('/api/bidding-rounds', {
    params: {
      property: propertyId
    }
  })
}

export function createBiddingRound (payload) {
  return httpClient.post('/api/bidding-rounds', payload)
}

export function updateBiddingRound (biddingRoundId, payload) {
  return httpClient.patch(`/api/bidding-rounds/${biddingRoundId}`, payload)
}

export function deleteBiddingRound (biddingRoundId) {
  return httpClient.delete(`/api/bidding-rounds/${biddingRoundId}`)
}

export function getPropertyRoomsOverview (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/rooms-overview`)
}

export function getPropertyLevelsBulk (propertyId) {
  return httpClient.get(`/api/v3/property/${propertyId}/levels/bulk`)
}

export function updatePropertyLevelsBulk (propertyId, payload) {
  return httpClient.put(`/api/v3/property/${propertyId}/levels/bulk`, payload)
}

export function deletePropertyLevel (propertyId, levelId) {
  return httpClient.delete(`/api/v3/property/${propertyId}/levels/${levelId}`)
}

export function getPropertyEstimates (propertyId) {
  return httpClient.get(`/api/properties/${propertyId}/estimates`)
}

export function createPropertyEstimate (propertyId, payload) {
  return httpClient.post(`/api/properties/${propertyId}/estimates`, payload)
}

export function deletePropertyEstimate (propertyId, estimateId) {
  return httpClient.delete(`/api/properties/${propertyId}/estimates/${estimateId}`)
}
