<template>
  <div class="tw-relative tw-mb-4 md:tw-my-4 tw-flex-grow">
    <input
      ref="query"
      type="text"
      :placeholder="placeholderText"
      class="form-control"
      :disabled="!canSearch"
      @blur="hideResults"
      @focus="showResults = true"
      @input="search"
    >
    <div v-if="results && showResults" class="tw-rounded tw-absolute tw-bg-white tw-z-20 tw-p-4 tw-shadow-md tw-w-full tw-left-0 tw-right-0">
      <div class="row">
        <div :key="index" class="col-sm-4" v-for="(type, index) in Object.keys(results)">
          <h2>{{ resultHeader(type) }}</h2>
          <SearchResult :RenderComponent="renderComponent(type)" :key="index" :value="result.object"
                        @result-clicked="clicked(result, type)" v-for="(result, index) in results[type]">
          </SearchResult>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

import SearchResult from './SearchResult'
import ContactRenderComponent from './ContactRenderComponent'
import PropertyRenderComponent from './PropertyRenderComponent'
import ProjectRenderComponent from './ProjectRenderComponent'

import debounce from 'lodash/debounce'

export default {
  name: 'Search',
  components: {
    SearchResult
  },
  data: function () {
    return {
      query: '',
      results: {
        relation: [],
        property: [],
        project: []
      },
      showResults: false
    }
  },
  computed: {
    canSearch () {
      return process.env.VUE_APP_ENVIRONMENT === 'prod'
    },
    placeholderText () {
      return this.canSearch ? 'Zoeken naar contacten, panden of projecten' : 'Zoeken via index enkel mogelijk in live-omgeving'
    }
  },
  methods: {
    renderComponent (type) {
      if (type === 'relation') {
        return ContactRenderComponent
      }
      if (type === 'property') {
        return PropertyRenderComponent
      }
      if (type === 'project') {
        return ProjectRenderComponent
      }
    },
    hideResults () {
      const v = this
      window.setTimeout(function () {
        v.showResults = false
      }, 150)
    },
    resultHeader (type) {
      if (type === 'relation') {
        return 'Contacten'
      }
      if (type === 'property') {
        return 'Panden'
      }
      if (type === 'project') {
        return 'Projecten'
      }
    },
    clicked (result, type) {
      if (type === 'relation') {
        this.$router.push({ name: 'ContactDetails', params: { id: result.id } })
      }
      if (type === 'property') {
        this.$router.push({ name: 'PropertyDetails', params: { id: result.id } })
      }
      if (type === 'project') {
        this.$router.push({ name: 'ProjectDetails', params: { id: result.id } })
      }
    },
    search: debounce(
      function () {
        const query = this.$refs.query.value
        if (query) {
          $.ajax({
            url: `/api/search?query=${query}`
          }).done(results => {
            this.results = {
              relation: [],
              property: [],
              project: []
            }
            results.forEach(result => {
              const type = result._type
              this.results[type].push(result._source)
            })
          })
        }
      },
      300,
      { maxWait: 3000 }
    )
  }
}
</script>
