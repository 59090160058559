<template>
  <nav class="tw-text-sm navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse">
      <div class="nav-header tw-bg-[#3e495f] tw-px-4 md:tw-py-6 tw-py-2 tw-text-gray-400 tw-flex md:tw-justify-center tw-justify-between tw-font-semibold">
        <div>
          <a href="/">
            <img src="@/assets/img/dewaele_groep_logo.svg" alt="Dewaele Vastgoed Groep" class="tw-mx-auto md:tw-w-24 md:tw-h-auto tw-h-6 tw-w-auto"/>
          </a>
          <router-link v-if="user" :to="{ name: 'Profile' }" @click.native="showDropdown = false" class="dropdown-toggle tw-hidden md:tw-block">
            <span class="block tw-mt-4 tw-text-center tw-text-white tw-text-sm tw-font-semibold">
              {{ user.firstName }} {{ user.lastName }}
            </span>
          </router-link>
        </div>
        <button type="button" class="sm:tw-hidden tw-self-start tw-mt-0.5" @click="showDropdown = !showDropdown">
          <i :class="['fas fa-lg', showDropdown ? 'fa-times' : 'fa-bars']" />
        </button>
      </div>

      <ul :class="['nav metismenu tw-py-2 tw-bg-[#3e495f] sm:tw-block', { 'tw-hidden': !showDropdown }]">
        <!-- Show only on mobile, user profile link is visible in header on larger screens-->
        <li :class="['md:!tw-hidden', { active: $route.name === 'Profile' }]">
          <router-link :to="{ name: 'Profile' }" @click.native="showDropdown = false">
            <i class="fa fa-th-large" />
            Mijn profiel
          </router-link>
        </li>

        <li :class="{ active: $route.name === 'Dashboard' }">
          <router-link :to="{ name: 'Dashboard' }" @click.native="showDropdown = false">
            <i class="fa fa-th-large" />
            Dashboard
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'Planner' }" @click.native="showDropdown = false">
            <i class="fa fa-calendar" />
            Agenda
          </router-link>
        </li>

        <li :class="{ 'active': activeNavGroup === 'Portfolio' }">
          <a @click="toggleMenu">
            <i class="fa fa-home" />
            Portefeuille
            <span class="fa arrow" />
          </a>
          <ul :class="['nav nav-second-level', { 'collapse': activeNavGroup !== 'Portfolio' }]">
            <li :class="{ active: $route.name === 'PropertySearch' }">
              <router-link :to="{ name: 'PropertySearch' }" @click.native="showDropdown = false">
                Panden
              </router-link>
            </li>
            <li :class="{ active: $route.name === 'ProjectSearch' }">
              <router-link :to="{ name: 'ProjectSearch' }" @click.native="showDropdown = false">
                Projecten
              </router-link>
            </li>
            <li :class="{ active: $route.name === 'PropertyGroups' }">
              <router-link :to="{ name: 'PropertyGroups' }" @click.native="showDropdown = false">
                Groepen
              </router-link>
            </li>
            <li :class="{ active: $route.name === 'PublicationSearch' }">
              <router-link :to="{ name: 'PublicationSearch' }" @click.native="showDropdown = false">
                Publicaties
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="{ 'active': activeNavGroup === 'Contacts' }">
          <a @click="toggleMenu">
            <i class="fa fa-user" />
            Contacten
            <span class="fa arrow" />
          </a>
          <ul :class="['nav nav-second-level', { 'collapse': activeNavGroup !== 'Contacts' }]">
            <li :class="{ active: $route.name === 'ContactSearch' }">
              <router-link :to="{ name: 'ContactSearch' }" @click.native="showDropdown = false">
                Contacten
              </router-link>
            </li>
            <!-- <li :class="{ active: $route.name === 'ContactGroups' }">
              <router-link :to="{ name: 'ContactGroups' }" @click.native="showDropdown = false">
                Groepen
              </router-link>
            </li> -->
            <li :class="{ active: $route.name === 'ContactExport' }">
              <router-link :to="{ name: 'ContactExport' }" @click.native="showDropdown = false">
                Exporteren
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="{ 'active': activeNavGroup === 'Transactions' }">
          <a @click="toggleMenu">
            <i class="fa fa-user" />
            Transacties
            <span class="fa arrow" />
          </a>
          <ul :class="['nav nav-second-level', { 'collapse': activeNavGroup !== 'Transactions' }]">
            <li>
              <a href="/transaction/new">
                Nieuw
              </a>
            </li>
            <li>
              <a href="/transaction/search">
                Zoeken
              </a>
            </li>
            <li>
              <a href="/transaction">
                Overzicht
              </a>
            </li>
            <li>
              <a href="/transaction/list/to_complete">
                Af te werken
              </a>
            </li>
            <li>
              <a href="/transaction/referral/owned">
                Aanbrengen
              </a>
            </li>
            <li>
              <a href="/transaction/list/ovab">
                Opschortende VW
              </a>
            </li>
            <li>
              <a href="/transaction/list/focus">
                Focuspanden
              </a>
            </li>
            <li>
              <a href="/transaction/list/action">
                Te controleren
              </a>
            </li>
            <li>
              <a href="/transaction/list/financial">
                Financieel
              </a>
            </li>
            <li :class="{ active: $route.name === 'TransactionAdvances' }">
              <router-link :to="{ name: 'TransactionAdvances' }" @click.native="showDropdown = false">
                Voorschotten
              </router-link>
            </li>
            <li :class="{ active: $route.name === 'TransactionListCosts' }">
              <router-link :to="{ name: 'TransactionListCosts' }" @click.native="showDropdown = false">
                Overzicht kosten
              </router-link>
            </li>
            <li :class="{ active: $route.name === 'TransactionListInvoices' }">
              <router-link :to="{ name: 'TransactionListInvoices' }" @click.native="showDropdown = false">
                Overzicht facturen
              </router-link>
            </li>
          </ul>
        </li>

        <!-- <li class="">
          <a href="/reports">
            <i class="fa fa-line-chart" />
            Rapporten
            <span class="fa arrow" />
          </a>
        </li> -->

        <li :class="{ 'active': activeNavGroup === 'Organization' }">
          <a @click="toggleMenu">
            <i class="fa fa-users" />
            Organisatie
            <span class="fa arrow" />
          </a>
          <ul :class="['nav nav-second-level', { 'collapse': activeNavGroup !== 'Organization' }]">
            <li :class="{ active: $route.name === 'OrganizationOfficeList' }">
              <router-link :to="{ name: 'OrganizationOfficeList' }" @click.native="showDropdown = false">
                Kantoren
              </router-link>
            </li>
            <li>
              <a href="/organization/collaborators">Medewerkers</a>
            </li>
            <li>
              <a href="/poules">Poules</a>
            </li>
          </ul>
        </li>

        <li
          v-if="collaborator && collaborator.hasPerm('VIEW_COMMISSION_LISTS')"
          :class="{ 'active': activeNavGroup === 'Commission' }"
        >
          <a @click="toggleMenu">
            <i class="fa fa-eur" />
            Commissielijsten
            <span class="fa arrow" />
          </a>
          <ul :class="['nav nav-second-level', { 'collapse': activeNavGroup !== 'Commission' }]">
            <li :class="{ active: $route.name === 'CommissionLists' }">
              <router-link :to="{ name: 'CommissionLists' }" @click.native="showDropdown = false">
                Overzicht
              </router-link>
            </li>
            <li :class="{ active: $route.name === 'CommissionTransactions' }">
              <router-link :to="{ name: 'CommissionTransactions' }" @click.native="showDropdown = false">
                Transacties
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="{ 'active': activeNavGroup === 'Sales' }">
          <a @click="toggleMenu">
            <i class="fa fa-rocket" />
            Sales
            <span class="fa arrow" />
          </a>
          <ul :class="['nav nav-second-level', { 'collapse': activeNavGroup !== 'Sales' }]">
            <li :class="{ active: $route.name === 'Sales' && parseInt($route.query.vc) === 0 }">
              <router-link :to="{ name: 'Sales', query: { vc: 0 } }" @click.native="showDropdown = false">Dashboard</router-link>
            </li>
            <li :class="{ active: $route.name === 'Sales' && parseInt($route.query.vc) === 1 }">
              <router-link :to="{ name: 'Sales', query: { vc: 1 } }" @click.native="showDropdown = false">Intake</router-link>
            </li>
            <li :class="{ active: $route.name === 'Sales' && parseInt($route.query.vc) === 2 }">
              <router-link :to="{ name: 'Sales', query: { vc: 2 } }" @click.native="showDropdown = false">Verkoop</router-link>
            </li>
            <li :class="{ active: $route.name === 'Sales' && parseInt($route.query.vc) === 3 }">
              <router-link :to="{ name: 'Sales', query: { vc: 3 } }" @click.native="showDropdown = false">Verhuur</router-link>
            </li>
          </ul>
        </li>

        <li :class="{ 'active': activeNavGroup === 'Marketing' }">
          <a @click="toggleMenu">
            <i class="fa fa-magic" />
            Marketing
            <span class="fa arrow" />
          </a>
          <ul :class="['nav nav-second-level', { 'collapse': activeNavGroup !== 'Marketing' }]">
            <li :class="{ active: $route.name === 'CreateFlyer' }">
              <router-link :to="{ name: 'CreateFlyer' }" @click.native="showDropdown = false">
                Flyers
              </router-link>
            </li>
            <li>
              <a href="/marketing/footers">Footers</a>
            </li>
            <li :class="{ active: $route.name === 'Shop' }">
              <router-link :to="{ name: 'Shop' }" @click.native="showDropdown = false">
                Shop
              </router-link>
            </li>
          </ul>
        </li>

        <li v-if="collaborator && collaborator.hasPerm('VIEW_BUDGET')" :class="{ 'active': activeNavGroup === 'Budget' }">
          <router-link to="/budget" @click.native="showDropdown = false">
            <i class="fa fa-euro" />
            Budget
          </router-link>
        </li>
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/drive/folders/1eo3dcK3N8Xfh48JNLcRKB3KhJdtBBxYT?usp=drive_link"
            @click="showDropdown = false"
          >
            <i class="fa fa-book" />
            Bibliotheek
          </a>
        </li>
        <li v-if="collaborator && collaborator.hasPerm('ACCESS_IT_TOOLS', true)" :class="{ 'active': activeNavGroup === 'Tools' }">
          <a @click="toggleMenu">
            <i class="fa fa-wrench" />
            IT tools
            <span class="fa arrow" />
          </a>
          <ul :class="['nav nav-second-level', { 'collapse': activeNavGroup !== 'Tools' }]">
            <li :class="{ active: $route.name === 'TransferTools' }">
              <router-link :to="{ name: 'TransferTools' }" @click.native="showDropdown = false">
                Uit dienst
              </router-link>
            </li>
            <li :class="{ active: $route.name === 'OrganimmoImport' }">
              <router-link :to="{ name: 'OrganimmoImport' }" @click.native="showDropdown = false">
                Organimmo Import
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import $ from 'jquery'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'Navigation',
  data () {
    return {
      showDropdown: false
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapGetters('account', ['collaborator']),

    activeNavGroup () {
      return this.$route.meta?.navigationGroupName
    }
  },
  watch: {
    collaborator (value) {
      if (value) this.setPermissions()
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    ...mapMutations('account', ['setPermissions']),

    toggleMenu (event) {
      $(event.target).parent().toggleClass('active')
      $(event.target).next().toggleClass('in')
    },
    handleClickOutside (evt) {
      if (!this.$el.contains(evt.target)) {
        this.showDropdown = false
      }
    }
  }
}
</script>

<style scoped>
i {
  display: inline-block; /* Safe fallback, in case the global styles for icons change */
  width: 13px;
}
</style>
