import { httpClient } from './axiosClients'
import { commaSerializer } from './paramSerializers'

export function getProjectById (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}`)
}

export function createProject (payload) {
  return httpClient.post('/api/v3/project', payload)
}

export function updateProjectDetails (projectId, payload) {
  return httpClient.patch(`/api/v3/project/${projectId}`, payload)
}

export function updateProjectLogo (projectId, logo) {
  // Files cannot be sent as json
  const payload = new FormData()
  payload.append('logo', logo)
  return httpClient.patch(`/api/v3/project/${projectId}/logo`, payload)
}

// We provide an empty object as default value to payload because we de-structure it later on
export function getProjectFiles (payload = {}) {
  const { projectId, url = `/api/v3/project/${projectId}/files`, params } = payload
  return httpClient.get(url, { params })
}

export function uploadFile (propertyId, payload) {
  return httpClient.post(
    `/api/v3/project/${propertyId}/files/upload`,
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export function createProjectFile (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/files`, payload)
}

export function createProjectFilesBulk (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/create-bulk`, payload)
}

export function downloadProjectFiles (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/files/download`, payload)
}

export function updateProjectFile (projectId, file) {
  return httpClient.patch(`/api/v3/project/${projectId}/files/${file.id}`, file)
}

export function deleteProjectFile (projectId, fileId) {
  return httpClient.delete(`/api/v3/project/${projectId}/files/${fileId}`)
}

export function deleteProjectFilesMultiple (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/files/delete`, payload)
}

export function pollProjectDownload (projectId, jobId) {
  return httpClient.get(`/api/v3/project/${projectId}/files/download/${jobId}`)
}

export function getProjectFilesAllIds (payload) {
  const { projectId, url = `/api/v3/project/${projectId}/files/ids`, params } = payload
  return httpClient.get(url, { params })
}

export function getProjectFilesFromIds (projectId, params) {
  return httpClient.get(`/api/v3/project/${projectId}/files/file-data`, {
    params,
    paramsSerializer: commaSerializer
  })
}

export function getProjectFileFolders (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/folders`, { params: { page_size: 1000 } })
}

export function createProjectFileFolder (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/folders`, payload)
}

export function deleteProjectFileFolder (projectId, folderId) {
  return httpClient.delete(`/api/v3/project/${projectId}/folders/${folderId}`)
}

export function editProjectFileFolder (projectId, payload) {
  return httpClient.put(`/api/v3/project/${projectId}/folders/${payload.id}`, payload)
}

export function addProjectFilesToFolder (projectId, folderId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/folders/${folderId}/files/add`, payload)
}

export function removeProjectFilesFromFolder (projectId, folderId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/folders/${folderId}/files/remove`, payload)
}

export function getProjectStickers (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/stickers`)
}

export function createProjectSticker (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/stickers`, payload)
}

export function deleteProjectSticker (propertyId, stickerId) {
  return httpClient.delete(`/api/v3/project/${propertyId}/stickers/${stickerId}`)
}

export function getProjectContentBlocks (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/content_blocks`)
}

export function createProjectContentBlock (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/content_blocks`, payload)
}

export function updateProjectContentBlock (contentBlockId, payload) {
  return httpClient.patch(`/api/v3/project/content_blocks/${contentBlockId}`, payload)
}

export function deleteProjectContentBlock (contentBlockId) {
  return httpClient.delete(`/api/v3/project/content_blocks/${contentBlockId}`)
}

export function getProjectDescriptions (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/descriptions`)
}

export function updateProjectDescriptionsBulk (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/descriptions/bulk`, payload)
}

export function updateProjectContentBlocksOrder (projectId, payload) {
  return httpClient.put(`/api/v3/project/${projectId}/content_blocks/order`, payload)
}

export function updateProjectPicturesBulk (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/pictures/bulk`, payload)
}

export function optimizeProjectPictures (projectId) {
  return httpClient.post(`/api/v3/project/${projectId}/pictures/optimize`)
}

export function toggleProjectPicturePrivacy (projectId, pictureId) {
  return httpClient.post(`/api/v3/project/${projectId}/pictures/${pictureId}/toggle-privacy-status`)
}

export function getProjectFileAsTemplate (projectId, fileId) {
  return httpClient.get(`/api/v3/project/${projectId}/files/${fileId}/mail-template`)
}

export function projectFilesDirectDownload (projectId, documentId) {
  return window.open(`/api/v3/project/${projectId}/files/${documentId}/direct-download`, '_blank')
}

export function getProjectCommunications (payload = {}) {
  const { projectId, url = `/api/v3/project/${projectId}/communications`, params } = payload
  return httpClient.get(url, { params })
}

export function createProjectCommunication (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/communications`, payload)
}

export function updateProjectCommunication (projectId, communicationId, payload) {
  return httpClient.patch(`/api/v3/project/${projectId}/communications/${communicationId}`, payload)
}

export function deleteProjectCommunication (projectId, communicationId) {
  return httpClient.delete(`/api/v3/project/${projectId}/communications/${communicationId}`)
}

export function getProjectOwners (projectId, params) {
  return httpClient.get(`/api/v3/project/${projectId}/owners`, { params })
}

export function updateProjectOwners (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/owners`, payload)
}

export function getProjectPortalPublications (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/portal-publications`)
}

export function createProjectPortalPublication (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/portal-publications`, payload)
}

export function updateProjectPortalPublication (projectId, publicationId, payload) {
  return httpClient.patch(`/api/v3/project/${projectId}/portal-publications/${publicationId}`, payload)
}

export function toggleProjectPublicationBufferPeriod (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/portal-publications/toggle-buffer-period`, payload)
}

export function getProjectPublicationActions (payload) {
  const { projectId, url = `/api/v3/project/${projectId}/publication-actions`, params } = payload
  return httpClient.get(url, { params })
}

export function createProjectPublicationAction (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/publication-actions`, payload)
}

export function updateProjectPublicationAction (projectId, projectPublicationActionId, payload) {
  return httpClient.patch(`/api/v3/project/${projectId}/publication-actions/${projectPublicationActionId}`, payload)
}

export function deleteProjectPublicationAction (projectId, projectPublicationActionId) {
  return httpClient.delete(`/api/v3/project/${projectId}/publication-actions/${projectPublicationActionId}`)
}

export function getProjectPromoters () {
  return httpClient.get('/api/v3/project/promoters')
}

export function getProjectArchitects () {
  return httpClient.get('/api/v3/project/architects')
}

export function getProjectEvents (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/events`)
}

export function createProjectEvent (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/events`, payload)
}

export function deleteProjectEvent (projectId, eventId) {
  return httpClient.delete(`/api/v3/project/${projectId}/events/${eventId}`)
}

export function validateProjectReference (reference) {
  return httpClient.get(`/api/v3/project/validate-reference?reference=${reference}`)
}

export function getProjectOffices (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/offices`)
}

export function updateProjectOffices (projectId, payload) {
  return httpClient.patch(`/api/v3/project/${projectId}/offices/edit`, payload)
}

export function makeProjectOfficePrimary (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/offices/set-primary`, payload)
}

export function getProjectGeolocation (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/geolocation`)
}

export function updateProjectGeolocation (projectId, payload) {
  return httpClient.patch(`/api/v3/project/${projectId}/geolocation`, payload)
}

export function getProjectMarketing (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/marketing`)
}

export function updateProjectMarketing (projectId, payload) {
  return httpClient.patch(`/api/v3/project/${projectId}/marketing`, payload)
}

export function getProjectCollaborators (payload) {
  const { projectId, url = `/api/v3/project/${projectId}/collaborators`, params } = payload
  return httpClient.get(url, { params })
}

export function updateProjectCollaborators (projectId, payload) {
  return httpClient.patch(`/api/v3/project/${projectId}/collaborators/edit`, payload)
}

export function updateProjectCollaborator (projectId, collaboratorId, payload) {
  return httpClient.patch(`/api/v3/project/${projectId}/collaborators/${collaboratorId}`, payload)
}

export function setProjectArchived (projectId) {
  return httpClient.post(`/api/v3/project/${projectId}/set-archived`)
}

export function setProjectDeleted (projectId) {
  return httpClient.post(`/api/v3/project/${projectId}/set-deleted`)
}

export function getProjectLegal (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/legal`)
}

export function updateProjectLegal (projectId, payload) {
  return httpClient.patch(`/api/v3/project/${projectId}/legal`, payload)
}

export function getProjectLegalNotices (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/legal-notices`)
}

export function getProjectEnergy (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/energy`)
}

export function getProjectAddressableContacts (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/addressable-contacts`)
}

export function updateProjectEnergy (projectId, payload) {
  return httpClient.patch(`/api/v3/project/${projectId}/energy`, payload)
}

export function searchProjects ({ params }) {
  return httpClient.get('/api/v3/project/search', { params })
}

export function importEntitiesStart (projectId, payload) {
  return httpClient.post(
    `/api/v3/project/${projectId}/import-entities/start`,
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export function importEntitiesConfirm (projectId, payload) {
  return httpClient.post(
    `/api/v3/project/${projectId}/import-entities/confirm`,
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export function getImportEntitiesJobStatus (jobId) {
  return httpClient.get(`/api/v3/import-entities-jobs/${jobId}/status`)
}
// We provide an empty object as default value to payload because we de-structure it later on
export function getProjects (payload = {}) {
  const { url = '/api/v3/project', params } = payload
  return httpClient.get(url, {
    params,
    paramsSerializer: commaSerializer
  })
}

export function toggleProjectPictureInheritance (projectId, projectPictureId) {
  return httpClient.post(`/api/v3/project/${projectId}/pictures/${projectPictureId}/toggle-inheritance`)
}

export function getProjectAuditTrail (payload) {
  const { projectId, url = `/api/v3/project/${projectId}/audit-trail`, params } = payload
  return httpClient.get(url, { params })
}

export function getProjectStatistics (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/statistics`)
}

export function getProjectInvoiceContacts (projectId, params) {
  return httpClient.get(`/api/projects/${projectId}/entity-invoice-recipient-groupings`, { params })
}

export function updateProjectInvoiceContacts (projectId, payload) {
  return httpClient.post(`/api/projects/${projectId}/entity-invoice-recipient-groupings`, payload)
}

export function startProjectInvoice (projectId, payload) {
  return httpClient.post(`/api/projects/${projectId}/invoicing/start`, payload)
}

export function generateProjectProformaInvoices (projectId, payload) {
  return httpClient.post(`/api/projects/${projectId}/invoicing/proforma`, payload)
}

export function createProjectProformaInvoice (projectId, payload) {
  return httpClient.post(`/api/projects/${projectId}/proforma-invoices`, payload)
}

export function storeProjectGutenborgFile (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/store-gutenborg-file`, payload)
}

export function getProjectContacts (payload) {
  const { projectId, url = `/api/v3/project/${projectId}/contacts`, params } = payload
  return httpClient.get(url, { params })
}

export function createProjectContact (projectId, payload) {
  return httpClient.post(`/api/v3/project/${projectId}/contacts`, payload)
}

export function updateProjectContact (projectId, projectContactId, payload) {
  return httpClient.patch(`/api/v3/project/${projectId}/contacts/${projectContactId}`, payload)
}

export function deleteProjectContact (projectId, projectContactId) {
  return httpClient.delete(`/api/v3/project/${projectId}/contacts/${projectContactId}`)
}

export function getProjectHoverable (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/hoverable`)
}

export function getProjectPictures (projectId) {
  return httpClient.get(`/api/v3/project/${projectId}/pictures`)
}
